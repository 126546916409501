import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PaymentsState {
  isFetchingPayMethods: boolean;
  isFetchingPayMethodContent: boolean;

  responsive: string;

  payMethods?: PaymentMethods[];
  payMethodContent?: PaymentMethodsContent;
}

export interface PaymentMethods {
  id: number;
  label: string;
  icon: string;
  action: string;
  attributes: Attribute[];
}

export interface Attribute {
  name: string;
  label: string;
  type: string;
  options?: Option[];
  input: string;
  order: number;
}

export interface Option {
  label: string;
  value: string;
}

export interface PaymentMethodsContent {
  id: number;
  payloadData: PaymentData;
  type?: string;
  content?: string;
}

export interface PaymentData {
  id?: number;
  chain?: string;
  token?: string;
  amount?: number;
  transfer_code?: string;
  transfer_pix?: string;
}

const initialState: PaymentsState = {
  isFetchingPayMethods: false,
  isFetchingPayMethodContent: false,
  payMethods: undefined,
  payMethodContent: undefined,
  responsive: "",
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    toggleAppState: (state: PaymentsState, action: PayloadAction<string>) => {
      state.responsive = action.payload;
    },
    getPayMethodsRequested: (state: PaymentsState) => {
      state.isFetchingPayMethods = true;
    },
    getPayMethodsSucceded: (
      state: PaymentsState,
      action: PayloadAction<PaymentMethods[]>
    ) => {
      state.isFetchingPayMethods = false;
      state.payMethods = action.payload;
    },
    getPayMethodsFailed: (state: PaymentsState) => {
      state.isFetchingPayMethods = false;
    },
    getPayMethodContentRequested: (
      state: PaymentsState,
      action: PayloadAction<PaymentMethodsContent>
    ) => {
      state.isFetchingPayMethodContent = true;
      action;
    },
    getPayMethodContentSucceded: (
      state: PaymentsState,
      action: PayloadAction<PaymentMethodsContent>
    ) => {
      state.isFetchingPayMethodContent = false;
      state.payMethodContent = action.payload;
    },
    getPayMethodContentFailed: (state: PaymentsState) => {
      state.isFetchingPayMethodContent = false;
    },
    clearPayMethodContent: (state: PaymentsState) => {
      state.payMethodContent = undefined;
    },
  },
});

export const {
  getPayMethodsRequested,
  getPayMethodsSucceded,
  getPayMethodsFailed,
  getPayMethodContentRequested,
  getPayMethodContentSucceded,
  getPayMethodContentFailed,
  clearPayMethodContent,
  toggleAppState,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
