/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
// oddslib.ts

// TypeScript declarations for node.js environment

// Helper functions and constants
const __defProp = Object.defineProperty;
const __markAsModule = (target: any) =>
  __defProp(target, "__esModule", { value: true });
const __export = (target: any, all: any) => {
  __markAsModule(target);
  for (const name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// Function to approximate fractional representation
function approximateFraction(d: number, precision: number): string | undefined {
  const numerators: number[] = [0, 1];
  const denominators: number[] = [1, 0];
  const maxNumerator = getMaxNumerator(d);
  let d2 = d;
  let calcD: number;
  const prevCalcD: number | typeof NaN = NaN;
  const acceptableError = Math.pow(10, -precision) / 2;
  for (let i = 2; i < 1e3; i++) {
    const L2 = Math.floor(d2);
    numerators[i] = L2 * numerators[i - 1] + numerators[i - 2];
    if (Math.abs(numerators[i]) > maxNumerator) return;
    denominators[i] = L2 * denominators[i - 1] + denominators[i - 2];
    calcD = numerators[i] / denominators[i];
    if (Math.abs(calcD - d) < acceptableError || calcD == prevCalcD) {
      return numerators[i].toString() + "/" + denominators[i].toString();
    }
    d2 = 1 / (d2 - L2);
  }
}

// Function to determine max numerator
function getMaxNumerator(f: number): number {
  let f2: string | null = null;
  let ixe = f.toString().indexOf("E");
  if (ixe == -1) ixe = f.toString().indexOf("e");
  if (ixe == -1) f2 = f.toString();
  else f2 = f.toString().substring(0, ixe);
  let digits: string | null = null;
  const ix = f2.toString().indexOf(".");
  if (ix == -1) digits = f2;
  else if (ix === 0) digits = f2.substring(1, f2.length);
  else if (ix < f2.length)
    digits = f2.substring(0, ix) + f2.substring(ix + 1, f2.length);
  let L: any = digits;
  const numDigits = L?.toString().length;
  const L2 = f;
  let numIntDigits = L2.toString().length;
  if (L2 === 0) numIntDigits = 0;
  numDigits;
  const numDigitsPastDecimal = numDigits! - numIntDigits;
  let i;
  for (i = numDigitsPastDecimal; i > 0 && L % 2 === 0; i--) L /= 2;
  for (i = numDigitsPastDecimal; i > 0 && L % 5 === 0; i--) L /= 5;
  return L;
}

// Format definitions and conversions
export interface Format {
  from: (value: string | number) => number;
  to: (options?: {
    precision?: number;
    percentage?: boolean;
  }) => string | number;
}

export const FORMATS: Record<string, Format> = {
  decimal: {
    from: (decimal: string | number): number => {
      decimal = parseFloat(decimal.toString());
      if (decimal <= 1) {
        throw new Error("Outside valid range.");
      }
      return decimal;
    },
    to: function (this: { decimalValue: number }): number {
      return this.decimalValue;
    },
  },
  moneyline: {
    from: (moneyline: string | number): number => {
      moneyline = parseFloat(moneyline.toString());
      if (moneyline >= 0) {
        return moneyline / 100 + 1;
      }
      return 100 / -moneyline + 1;
    },
    to: function (this: { decimalValue: number }): number {
      if (this.decimalValue >= 2) {
        return fixFloatError((this.decimalValue - 1) * 100);
      }
      return fixFloatError(-100 / (this.decimalValue - 1));
    },
  },
  hongKong: {
    from: (hongKong: string | number): number => {
      hongKong = parseFloat(hongKong.toString());
      if (hongKong < 0) {
        throw new Error("Outside valid range.");
      }
      return hongKong + 1;
    },
    to: function (this: { decimalValue: number }): number {
      return fixFloatError(this.decimalValue - 1);
    },
  },
  impliedProbability: {
    from: (ip: string | number): number => {
      if (typeof ip === "string" && ip.slice(-1) == "%") {
        ip = parseFloat(ip) / 100;
      } else {
        ip = parseFloat(ip.toString());
      }
      if (ip <= 0 || ip >= 1) {
        throw new Error("Outside valid range");
      }
      return 1 / ip;
    },
    to: function (
      this: { decimalValue: number },
      options?: { precision?: number; percentage?: boolean }
    ): string | number {
      options = options || {}; // Ensure options is not undefined

      if (options.percentage) {
        let value = fixFloatError(100 / this.decimalValue);
        if (options.precision !== null && options.precision !== undefined) {
          value = decimalAdjust("round", value, -options.precision);
        }
        return value.toString() + "%";
      }

      return fixFloatError(1 / this.decimalValue);
    },
  },
  fractional: {
    from: (n: string | number): number => {
      const pieces = n.toString().split("/");
      const num = parseFloat(pieces[0]);
      let d: number;
      if (pieces.length === 2) {
        d = parseFloat(pieces[1]);
      } else if (pieces.length === 1) {
        d = 1;
      } else {
        throw new Error("Invalid fraction");
      }
      if (num === 0 || d === 0 || num / d <= 0) {
        throw new Error("Outside valid range");
      }
      return 1 + num / d;
    },
    to: function (
      this: { decimalValue: number },
      options?: { precision?: number }
    ): string {
      return (
        approximateFraction(this.decimalValue - 1, options?.precision || 12) ||
        ""
      );
    },
  },
  malay: {
    from: (malay: string | number): number => {
      malay = parseFloat(malay.toString());
      if (malay <= -1 || malay > 1) {
        throw new Error("Outside valid range.");
      }
      if (malay < 0) {
        malay = -1 / malay;
      }
      return malay + 1;
    },
    to: function (this: { decimalValue: number }): number {
      if (this.decimalValue <= 2) {
        return fixFloatError(this.decimalValue - 1);
      }
      return fixFloatError(-1 / (this.decimalValue - 1));
    },
  },
  indonesian: {
    from: (indonesian: string | number): number => {
      indonesian = parseFloat(indonesian.toString());
      if (indonesian === 0) {
        throw new Error("Outside valid range.");
      }
      if (indonesian >= 1) {
        return indonesian + 1;
      }
      return -1 / indonesian + 1;
    },
    to: function (this: { decimalValue: number }): number {
      if (this.decimalValue < 2) {
        return fixFloatError(-1 / (this.decimalValue - 1));
      }
      return fixFloatError(this.decimalValue - 1);
    },
  },
};

export class Odds {
  // private constructor() {
  //   throw new Error(
  //     "This constructor is private, please use the from* functions"
  //   );
  // }

  static from(format: string, value: string | number): Odds {
    if (!FORMATS.hasOwnProperty(format)) {
      throw new Error("Unknown format " + format + ".");
    }
    const decimal = FORMATS[format].from(value);
    return new Odds(decimal);
  }

  private decimalValue: number;

  private constructor(decimalValue: number) {
    if (typeof decimalValue !== "number" || isNaN(decimalValue)) {
      throw new Error("Invalid odds");
    }
    this.decimalValue = fixFloatError(decimalValue);
  }

  to(
    format: string,
    options?: { precision?: number; percentage?: boolean }
  ): string | number {
    if (!FORMATS.hasOwnProperty(format)) {
      throw new Error("Unknown format " + format + ".");
    }
    options = Object.assign(
      {
        precision: null,
        percentage: false,
      },
      options
    );
    let ret = FORMATS[format].to.call(this, options);
    if (
      typeof ret === "number" &&
      options?.precision !== null &&
      options?.precision !== undefined
    ) {
      ret = decimalAdjust("round", ret, -options.precision);
    }
    return ret;
  }
}

export const from = Odds.from;

// Annotate the CommonJS export names for ESM import in node:
if (typeof module !== "undefined" && module.exports) {
  module.exports = {
    Odds,
    from,
  };
}

// Helper function to adjust decimal values
function fixFloatError(n: number): number {
  return parseFloat(n.toPrecision(12));
}

// Helper function to adjust decimal values based on type and precision
function decimalAdjust(
  type: "floor" | "ceil" | "round",
  value: number,
  exp: number
): number {
  if (typeof exp === "undefined" || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  if (value < 0) {
    return -decimalAdjust(type, -value, exp);
  }

  // Split the value into base and exponent parts
  const valueStr = value.toString().split("e");
  const base = parseFloat(valueStr[0]);
  const exponent = parseFloat(valueStr[1] || "0");

  // Calculate the new value using Math[type]
  const newValue = Math[type](base * Math.pow(10, exponent - exp));

  return parseFloat(newValue.toPrecision(12));
}
