import { instanceAxios } from "services/axiosServices";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { store } from "../store";
import {
  subscribeToBalanceWebsocketChannels,
  websocket,
} from "services/socketServices";
import {
  loginRequested,
  loginSucceded,
  loginFailed,
  registerRequested,
  registerSucceded,
  registerFailed,
  logoutRequested,
  logoutSucceded,
  logoutFailed,
  requestChangePassword,
  changePasswordFailed,
  requestResetPassword,
  resetPasswordSucceded,
  resetPasswordFailed,
  getAuthDetailsRequested,
  getAuthDetailsSucceded,
  getAuthDetailsFailed,
} from "./authSlice";
import { openAlert } from "../alert/alertSlice";
import {
  clearWalletState,
  saveBettingWalletId,
  getWalletSucceded,
} from "../wallet/walletSlice";
import { clearCouponsState } from "../coupon/couponSlice";
import { getHighlightsRequested } from "../games/gamesSlice";
import { getWalletConfigRequested } from "../config/configSlice";
import { closeDialog, openDialog } from "redux/dialog/dialogSlice";

export function* login({
  payload,
}: ReturnType<typeof loginRequested>): unknown {
  try {
    const response = yield call(instanceAxios.post, "/api/auth/login", payload);
    const { data } = response.data;
    sessionStorage.setItem("token", data.access_token);
    if (data.password_change) {
      yield put(openDialog({ type: "newPassword" }));
    }
    yield put(loginSucceded(data));
    yield put(getWalletSucceded(data.wallet));
    yield put(saveBettingWalletId(data.wallet.id));
    yield put(getWalletConfigRequested());
    yield put(getHighlightsRequested());

    subscribeToBalanceWebsocketChannels(
      response.data.data.access_token,
      data.wallet.id
    );
  } catch (error) {
    yield put(loginFailed());
  }
}

export function* register({
  payload,
}: ReturnType<typeof registerRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      "/api/auth/register",
      payload
    );
    const { data } = response.data;
    yield put(registerSucceded(data));
    yield put(
      openAlert({
        message: response.data.message,
        success: response.data.success,
        severity: "success",
        isAutoHide: false,
      })
    );
  } catch (error) {
    yield put(registerFailed());
  }
}
export function* logout(): unknown {
  try {
    websocket.leave("wallet." + store.getState().auth.authWallet?.id);
    yield call(instanceAxios.post, "/api/auth/logout");
    sessionStorage.clear();
    !sessionStorage.length && (yield put(logoutSucceded()));

    yield put(getHighlightsRequested());
    yield put(clearCouponsState());
    yield put(clearWalletState());
  } catch (error) {
    yield put(logoutFailed());
  }
}

export function* changePassword({
  payload,
}: ReturnType<typeof requestChangePassword>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      "/api/auth/changepassword",
      payload
    );
    if (response.data.success) {
      yield put(closeDialog());
    }
    yield put(
      openAlert({
        message: response.data.message,
        success: response.data.success,
        severity: "success",
        isAutoHide: true,
      })
    );
  } catch (error) {
    yield put(changePasswordFailed());
  }
}

export function* resetPassword({
  payload,
}: ReturnType<typeof requestResetPassword>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `/api/auth/passwordreset`,
      payload
    );
    yield put(resetPasswordSucceded());
    yield put(
      openAlert({
        message: response.data.message,
        success: response.data.success,
        severity: "success",
        isAutoHide: true,
      })
    );
  } catch (err) {
    console.error(err);
    resetPasswordFailed();
  }
}
export function* getAuthDetails(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/auth/detail");
    const { data } = response.data;
    yield put(getAuthDetailsSucceded(data));
    yield put(saveBettingWalletId(data.wallet.id));
  } catch (error) {
    yield put(getAuthDetailsFailed());
  }
}

export function* onLoginRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(loginRequested, login);
}
export function* onRegisterRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(registerRequested, register);
}
export function* onLogoutRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(logoutRequested, logout);
}
export function* onChangePassword(): Generator<unknown, void, unknown> {
  yield takeLatest(requestChangePassword, changePassword);
}
export function* onResetPasswordRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(requestResetPassword, resetPassword);
}
export function* ongetAuthDetailsRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getAuthDetailsRequested, getAuthDetails);
}

export function* authSagas(): Generator<unknown, void, unknown> {
  yield all([
    call(onLoginRequested),
    call(onRegisterRequested),
    call(onLogoutRequested),
    call(onChangePassword),
    call(onResetPasswordRequested),
    call(ongetAuthDetailsRequested),
  ]);
}
