import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LayoutToggleState {
  isDesktopLayout: boolean;
}

export interface ToggleInterface {
  status: boolean;
  caller?: string;
}

const initialState: LayoutToggleState = {
  isDesktopLayout: false,
};

export const layoutToggleSlice = createSlice({
  name: "layoutToggle",
  initialState,
  reducers: {
    toggleDesktopLayout: (
      state: LayoutToggleState,
      action: PayloadAction<ToggleInterface>
    ) => {
      state.isDesktopLayout = action.payload.status;
      // {
      //   action.payload.caller !== "mobile" && location.reload();
      // }
    },
  },
});

export const { toggleDesktopLayout } = layoutToggleSlice.actions;
export default layoutToggleSlice.reducer;
