import { instanceAxios } from "services/axiosServices";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getWalletConfigRequested,
  getWalletConfigSucceded,
  getWalletConfigFailed,
  getSkinConfigRequested,
  getSkinConfigSucceded,
  getSkinConfigFailed,
  getAuthSkinConfigRequested,
  getAuthSkinConfigSucceded,
  getAuthSkinConfigFailed,
  getBannersSucceded,
  getBannersFailed,
  getBannersRequested,
} from "./configSlice";
import { saveBettingConfigSucceded } from "../coupon/couponSlice";

export function* getSkin(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/skin/settings");
    const { data } = response.data;

    yield put(getSkinConfigSucceded(data));
    yield put(saveBettingConfigSucceded(data.betting));
  } catch (error) {
    yield put(getSkinConfigFailed());
  }
}

export function* getAuthSkin({
  payload,
}: ReturnType<typeof getAuthSkinConfigRequested>): unknown {
  try {
    const response = yield yield call(
      instanceAxios.get,
      `/api/wallet/settings/${payload}`
    );
    const { data } = response.data;
    yield put(saveBettingConfigSucceded(data.betting));
    yield put(getAuthSkinConfigSucceded(data));
  } catch (error) {
    yield put(getAuthSkinConfigFailed());
  }
}
export function* getWalletConfig(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/wallet/config");
    const { data } = response.data;
    yield put(getWalletConfigSucceded(data));
  } catch (error) {
    yield put(getWalletConfigFailed());
  }
}
export function* getBanners(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/skin/banners");
    const { data } = response.data;
    yield put(getBannersSucceded(data));
  } catch (error) {
    yield put(getBannersFailed());
  }
}
export function* ongetSkinConfigRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getSkinConfigRequested, getSkin);
}
export function* ongetAuthSkinConfigRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getAuthSkinConfigRequested, getAuthSkin);
}
export function* ongetWalletConfigRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getWalletConfigRequested, getWalletConfig);
}
export function* ongetBannersRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getBannersRequested, getBanners);
}
export function* configSagas(): unknown {
  yield all([
    call(ongetSkinConfigRequested),
    call(ongetAuthSkinConfigRequested),
    call(ongetWalletConfigRequested),
    call(ongetBannersRequested),
  ]);
}
