import Echo from "laravel-echo";
import { store } from "../redux/store";
import { updateWallet } from "redux/auth/authSlice";
import {
  updatePalimpsestConfig,
  updateMarketsConfig,
  updateEventsConfig,
  updateMarkets,
  getSnapshotRequested,
  getEventSnapshotRequested,
  updateEventMarkets,
} from "redux/live/liveSlice";
require("pusher-js");

export const websocket = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_SOCKET_KEY,
  wsHost: process.env.REACT_APP_SOCKET_WSHOST,
  wsPort: 443,
  wssPort: 443,
  enabledTransports: ["ws", "wss"],
  disableStats: true,
  forceTLS: true,
  authEndpoint: `${process.env.REACT_APP_BACKEND_PROTO}${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_WEBSOCKET_AUTH}`,
});
process.env.REACT_APP_HIDE_LIVE_SPORT !== "true" &&
  websocket.connector.pusher.connection.bind(
    "state_change",
    function (states: any) {
      console.log("websocket connection state", states.current);
      if (states.current == "connected") {
        process.env.REACT_APP_SKIN !== "vadmin" &&
          process.env.REACT_APP_SKIN !== "gsadmin" &&
          subscribeToLiveWebsocketChannels();
      } else if (
        (states.current == "failed" ||
          states.current == "disconnected" ||
          states.current == "unavailable") &&
        states.previous == "connected"
      )
        window.location.reload();
    }
  );

export const subscribeToLiveWebsocketChannels = (): void => {
  const langConfig = process.env.REACT_APP_LANGUAGE_OPTIONS;
  const jsonConfig = langConfig && JSON.parse(langConfig);
  const lang = jsonConfig.find(
    (el: Record<string, string>) =>
      el.locale === process.env.REACT_APP_DEFAULT_LANGUAGE
  ).header;
  websocket
    .channel(`live.update.${lang}`)
    .listen(".live.config.palimpsest.update", (event: string) => {
      try {
        store.dispatch(updatePalimpsestConfig(event));
      } catch (err) {
        console.log("p update");
      }
    })
    .listen(".live.config.markets.update", (event: string) => {
      try {
        store.dispatch(updateMarketsConfig(event));
      } catch (err) {
        console.log("mc update");
      }
    })
    .listen(".live.events.update", (event: string) => {
      try {
        store.dispatch(updateEventsConfig(event));
      } catch (err) {
        console.log("ec update");
      }
    });
  websocket
    .channel(`live.update`)
    .listen(".live.markets.update", (event: string) => {
      try {
        store.dispatch(updateMarkets(event));
      } catch (err) {
        console.log("m update");
      }
    });
  store.dispatch(getSnapshotRequested());
};
export const subscribeToLiveEventChannel = (eventId: number): void => {
  websocket
    .channel(`live.event.${eventId}`)
    .listen(".live.event.update", (event: string) => {
      try {
        store.dispatch(updateEventMarkets(event));
      } catch (err) {
        console.log("single m update");
      }
    });
  store.dispatch(
    getEventSnapshotRequested(store.getState().live.selectedEvent)
  );
};
export const subscribeToLiveUpdatetChannel = (lang: string): void => {
  websocket
    .channel(`live.update.${lang}`)
    .listen(".live.config.palimpsest.update", (event: string) => {
      store.dispatch(updatePalimpsestConfig(event));
    })
    .listen(".live.config.markets.update", (event: string) => {
      store.dispatch(updateMarketsConfig(event));
    })
    .listen(".live.events.update", (event: string) => {
      store.dispatch(updateEventsConfig(event));
    });
  store.dispatch(getSnapshotRequested());
};
export const subscribeToBalanceWebsocketChannels = (
  token: string,
  id: number
): void => {
  websocket.connector.options.auth.headers["Authorization"] = "Bearer " + token;
  websocket.private(`wallet.${id}`).listen(".wallet.update", (event: any) => {
    event.wallet && store.dispatch(updateWallet(event.wallet));
  });
};
