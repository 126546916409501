import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AlertState {
  visible: boolean;
  message: string;
  success: boolean;
  severity?: string;
  isAutoHide?: boolean;
}
interface IPayload {
  message: string;
  success: boolean;
  severity: string;
  isAutoHide: boolean;
}

const initialState: AlertState = {
  visible: false,
  success: false,
  message: "",
  severity: undefined,
  isAutoHide: undefined,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    openAlert: (state: AlertState, action: PayloadAction<IPayload>) => {
      const { message, severity, success, isAutoHide } = action.payload;
      state.visible = true;
      state.message = message;
      state.success = success;
      state.severity = severity;
      state.isAutoHide = isAutoHide;
    },
    closeAlert: (state: AlertState) => {
      state.visible = false;
      state.message = "";
      state.success = false;
      state.severity = undefined;
      state.isAutoHide = undefined;
    },
  },
});

export const { openAlert, closeAlert } = alertSlice.actions;

export default alertSlice.reducer;
