import {
  CheckedGroup,
  ICouponOdd,
  PlayedCoupon,
  PlayedCouponFixed,
  GroupComb,
} from "./couponSlice";
import { Combination } from "js-combinatorics";

/*****************find coupon type******** */
export const calculateCouponType = (
  data: ICouponOdd[],
  couponLength: number,
  eventsNr: number,
  tab: number
): string => {
  let type = "";
  const liveOdds =
    data && data.filter((element) => element.caller === "live").length > 0;
  const sportOdds =
    data && data.filter((element) => element.caller === "sport").length > 0;

  if (couponLength === 1) {
    /**single */
    if (sportOdds && !liveOdds) {
      /**prematch */
      type = "win_single";
    } else {
      /**mix||live */
      type = "win_live";
    }
  } else if (couponLength !== eventsNr) {
    /**integral */
    type = "win_integral";
  } else {
    /**system */
    if (tab === 1) {
      type = "win_system";
    } else {
      /**multiple */
      if (sportOdds && !liveOdds) {
        /**prematch */
        type = "win_multiple";
      } else {
        /**mix||live */
        type = "win_live";
      }
    }
  }

  return type;
};
/****multiple,single,integral funcions** */
export const calculateNrOfCombinations = (
  coupon: Record<string, ICouponOdd[]>
): number => {
  let mult = 1;
  Object.keys(coupon).map((key) => {
    mult = mult * coupon[key].length;
  });
  return mult;
};
export const calculateMultiplicationOfOddComb = (
  cartesianProduct: any
): number[] => {
  let oddProductArray: number[] = [];
  cartesianProduct &&
    cartesianProduct.map((item: any) => {
      let start = 1;
      item &&
        item.map((itemEl: any) => {
          if (itemEl.value && !itemEl.locked) {
            start = start * itemEl.value;
          }
        });
      oddProductArray = [...oddProductArray, start];
    });
  return oddProductArray;
};

export const calculateMultiplicationOfOddCombPlayedCoupon = (
  cartesianProduct: any
): number[] => {
  let oddProductArray: number[] = [];
  cartesianProduct &&
    cartesianProduct.map((item: any) => {
      let start = 1;
      item &&
        item.map((itemEl: any) => {
          if (itemEl.value) {
            start = start * itemEl.value;
          }
        });
      oddProductArray = [...oddProductArray, start];
    });
  return oddProductArray;
};

export const calculateArrayMaxMinValue = (
  type: string,
  data?: number[]
): number => {
  let result = 0;
  type &&
    data &&
    (type === "max"
      ? (result = Math.max(...data))
      : (result = Math.min(...data)));

  return result;
};
export const calculateObjArrayMaxMinValue = (
  type: string,
  data: CheckedGroup[]
): number => {
  let result = 0;
  type &&
    data &&
    data.length > 0 &&
    (type === "max"
      ? (result = data.reduce((previous, current) =>
          previous.maxOdd > current.maxOdd ? previous : current
        ).maxOdd)
      : (result = data.reduce((previous, current) =>
          previous.minOdd < current.minOdd ? previous : current
        ).minOdd));
  return result;
};
export const calculatePotentialWinArray = (
  combinationAmount: number,
  oddProduct: number[],
  oddBonus?: number[]
): number[] => {
  let potentialWinArray: number[] = [];
  oddBonus &&
    oddProduct &&
    oddProduct.map((item, index) => {
      const result =
        combinationAmount * item +
        (combinationAmount * item * oddBonus[index]) / 100;
      potentialWinArray = [...potentialWinArray, result];
    });

  return potentialWinArray;
};
export const calculatePotentialWinArrayPlayedCoupon = (
  combinationAmount: number,
  oddProduct: number[]
): number[] => {
  let potentialWinArray: number[] = [];
  oddProduct &&
    oddProduct.map((item, index) => {
      const result = combinationAmount * item;
      potentialWinArray = [...potentialWinArray, result];
    });

  return potentialWinArray;
};
interface ICalculatePotentialWinValue {
  isPotentialWinOverLimit: boolean;
  value: number;
}
export const calculatePotentialWinValue = (
  potentialWinArray: number[],
  limit?: number
): ICalculatePotentialWinValue => {
  let sum = 0;
  if (potentialWinArray && potentialWinArray.length > 0) {
    sum = potentialWinArray.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue;
    });
  }
  const dataToReturn = {
    isPotentialWinOverLimit: limit && sum && sum > limit ? true : false,
    value: limit && sum && sum > limit ? limit : sum,
  };
  return dataToReturn;
  // return limit && sum && sum > limit ? limit : sum;
};
export const calculateOddBonus = (
  cartesianProduct: any,
  bonus?: Record<string, number>,
  rankBonus?: number
): number[] => {
  let oddBonusArray: number[] = [];
  bonus &&
    (rankBonus || rankBonus === 0) &&
    cartesianProduct &&
    cartesianProduct.map((item: any) => {
      const result = item.filter(
        (itemEl: any) => itemEl.value && itemEl.value >= rankBonus
      );
      switch (result.length) {
        case result.length:
          bonus[result.length]
            ? (oddBonusArray = [...oddBonusArray, bonus[result.length]])
            : (oddBonusArray = [...oddBonusArray, 0]);
          break;
      }
    });
  return oddBonusArray;
};
/***************************************
 *
 *
 *
 *
 * ********************************** */

/********system functions ********** */
export const composeGrCombinations = (
  oddsLength: number,
  fixedOdds: PlayedCouponFixed[]
): Record<string, GroupComb> => {
  let obj = {};

  for (
    let key = fixedOdds.length > 0 ? fixedOdds.length : 1;
    key <= oddsLength;
    key++
  ) {
    const element = {
      nrComb: combinations(oddsLength, key, fixedOdds.length),
    };
    obj = {
      [key]: element,
      ...obj,
    };
  }

  return obj;
};
/** composeGrCombinations inner function */
const combinations = (odds: number, group: number, fix: number) => {
  odds -= fix;
  group -= fix;
  return Math.round(
    factorialize(odds) / (factorialize(group) * factorialize(odds - group))
  );
};
/** combinations inner function */
const factorialize = (number: number) => {
  if (number === 0 || number === 1) return 1;
  for (let i = number - 1; i >= 1; i--) {
    number *= i;
  }
  return number;
};
export const calculateAllCombinationsForCheckedGroups = (
  odds: ICouponOdd[],
  group: number,
  fixedOdds: PlayedCouponFixed[]
): ICouponOdd[][] => {
  const combinations = new Combination(odds, group);
  let validCombArr: ICouponOdd[][] = [];
  if (fixedOdds.length > 0) {
    [...combinations]?.map((combItem) => {
      const validComb =
        combItem.filter((comb) =>
          fixedOdds.find((fixedOdd) => fixedOdd.unique === comb.unique)
        ).length === fixedOdds.length;

      if (validComb) {
        validCombArr = [...validCombArr, combItem];
      }
    });
  } else {
    validCombArr = [...combinations];
  }
  return validCombArr;
};
export const calculateCombSum = (data: CheckedGroup[]): number => {
  let sum = 0;
  data &&
    data.map((item) => {
      sum += item.combination;
    });
  return sum;
};
/**combinationAmount,bettingAmount,minBonus,maxBonus,
 * maxPotentialWin,minPotentialWin*/
export const updateDataInCheckedGroups = (
  checkedGroups: Record<string, CheckedGroup>,
  amount: string | number,
  odds: ICouponOdd[],
  fixedOdds: PlayedCouponFixed[],
  bonus?: Record<string, number>,
  rankBonus?: number,
  limit?: number | string
): Record<string, CheckedGroup> => {
  Object.keys(checkedGroups)?.map((key) => {
    const calculatedCombAmount =
      Number(amount) / calculateCombSum(Object.values(checkedGroups));
    const combinations = calculateAllCombinationsForCheckedGroups(
      odds,
      Number(key),
      fixedOdds
    );
    const bonusArray = calculateBonusArrayPerGroup(
      combinations,
      calculatedCombAmount,
      bonus,
      rankBonus
    );
    const maxWinValue =
      calculateMultiplicationOfSystemOddComb(combinations) *
      calculatedCombAmount;
    const minWinValue =
      calculateMultiplicationOfSystemOddCombMin(combinations) *
      calculatedCombAmount;
    checkedGroups[key] = {
      ...checkedGroups[key],
      ["combinationAmount"]: calculatedCombAmount.toFixed(4).toString(),
      ["bettingAmount"]: (Number(amount) * checkedGroups[key].combination)
        .toFixed(2)
        .toString(),

      ["maxBonus"]: calculateSystemGrMaxBonus(bonusArray),
      ["minBonus"]: calculateSystemGrMinBonus(bonusArray),
      ["minPotentialWin"]:
        Number(limit) && minWinValue > Number(limit)
          ? Number(limit)
          : minWinValue,
      ["maxPotentialWin"]:
        Number(limit) && maxWinValue > Number(limit)
          ? Number(limit)
          : maxWinValue,
    };
  });
  return checkedGroups;
};
export const calculateMultiplicationOfSystemOddComb = (
  combinations: ICouponOdd[][]
): number => {
  let sum = 0;
  combinations.map((itemArr: any) => {
    let mult = 1;
    for (let i = 0; i < itemArr.length; i++) {
      mult = mult * itemArr[i].value;
    }
    sum = sum + mult;
  });
  return sum;
};
export const calculateMultiplicationOfSystemOddCombPlayedCoupon = (
  combinations: ICouponOdd[][]
): number => {
  let sum = 0;
  combinations.map((itemArr: any) => {
    let mult = 1;
    for (let i = 0; i < itemArr.length; i++) {
      itemArr[i].odd.void
        ? (mult = mult * 1)
        : (mult = mult * itemArr[i].value);
    }
    sum = sum + mult;
  });
  return sum;
};
export const calculateMultiplicationOfSystemOddCombMin = (
  combinations: ICouponOdd[][]
): any => {
  let array: number[] = [];
  combinations.map((itemArr: any) => {
    let mult = 1;
    for (let i = 0; i < itemArr.length; i++) {
      mult = mult * itemArr[i].value;
    }
    array = [...array, mult];
  });
  if (array.length) {
    return array.reduce((previous: any, current: any) =>
      previous < current ? previous : current
    );
  }
};

export const updatePotentialWinsInCheckedGroups = (
  checkedGroups: Record<string, CheckedGroup>,
  groupComb: Record<string, GroupComb>,
  odds: ICouponOdd[],
  fixedOdds: PlayedCouponFixed[]
): Record<string, CheckedGroup> => {
  Object.keys(checkedGroups)?.map((key) => {
    if (groupComb[key]) {
      const combinations = calculateAllCombinationsForCheckedGroups(
        odds,
        Number(key),
        fixedOdds
      );
      checkedGroups[key] = {
        ...checkedGroups[key],
        ["minPotentialWin"]:
          calculateMultiplicationOfSystemOddCombMin(combinations) *
          Number(checkedGroups[key].combinationAmount),
        ["maxPotentialWin"]:
          calculateMultiplicationOfSystemOddComb(combinations) *
          Number(checkedGroups[key].combinationAmount),
      };
    }
  });
  return checkedGroups;
};
export const updateCheckedGroupsOnOddRemove = (
  checkedGroups: Record<string, CheckedGroup>,
  groupComb: Record<string, GroupComb>
): Record<string, CheckedGroup> => {
  Object.keys(checkedGroups)?.map((key) => {
    if (!groupComb[key]) {
      delete checkedGroups[key];
    } else {
      checkedGroups[key] = {
        ...checkedGroups[key],
        ["combination"]: groupComb[key].nrComb,
      };
    }
  });
  return checkedGroups;
};
export const updateCheckedGroupsOnCombRemove = (
  checkedGroups: Record<string, CheckedGroup>,
  groupComb: Record<string, GroupComb>,
  maxcolSystem?: number
): Record<string, CheckedGroup> => {
  maxcolSystem &&
    Object.keys(checkedGroups)?.map((key) => {
      if (groupComb[key].nrComb >= maxcolSystem) {
        delete checkedGroups[key];
      } else {
        checkedGroups[key] = {
          ...checkedGroups[key],
          ["combination"]: groupComb[key].nrComb,
        };
      }
    });
  return checkedGroups;
};
export const calculateBonusArrayPerGroup = (
  combinations: any,
  combinationAmount: number,
  bonus?: Record<string, number>,
  rankBonus?: number
): number[] => {
  let calculatedValuesWithBonus: any = [];

  for (let i = 0; i < combinations.length; i++) {
    let bonusValue = 0;
    const result =
      (rankBonus || rankBonus === 0) &&
      combinations[i].filter((itm: any) => itm.value && itm.value >= rankBonus);
    if (bonus && result && result.length) {
      switch (result.length) {
        case result.length:
          bonus[result.length]
            ? (bonusValue = bonus[result.length])
            : (bonusValue = 0);
          break;
      }
    }
    if (bonusValue) {
      let mult = 1;
      for (let y = 0; y < combinations[i].length; y++) {
        mult = combinations[i][y].value * mult;
      }
      calculatedValuesWithBonus = [
        ...calculatedValuesWithBonus,
        (mult * combinationAmount * bonusValue) / 100,
      ];
    }
  }

  return calculatedValuesWithBonus;
};
export const calculateBonusArrayPerGroupPlayedCoupon = (
  combinations: any,
  combinationAmount: number,
  bonus?: Record<string, number>,
  rankBonus?: number
): number[] => {
  let calculatedValuesWithBonus: any = [];

  for (let i = 0; i < combinations.length; i++) {
    let bonusValue = 0;
    const result =
      (rankBonus || rankBonus === 0) &&
      combinations[i].filter(
        (itm: any) =>
          itm.value && (itm.odd.void ? 1 >= rankBonus : itm.value >= rankBonus)
      );
    if (bonus && result && result.length) {
      switch (result.length) {
        case result.length:
          bonus[result.length]
            ? (bonusValue = bonus[result.length])
            : (bonusValue = 0);
          break;
      }
    }
    if (bonusValue) {
      let mult = 1;
      for (let y = 0; y < combinations[i].length; y++) {
        mult = combinations[i][y].odd.void
          ? 1 * mult
          : combinations[i][y].value * mult;
      }
      calculatedValuesWithBonus = [
        ...calculatedValuesWithBonus,
        (mult * combinationAmount * bonusValue) / 100,
      ];
    }
  }

  return calculatedValuesWithBonus;
};
export const calculateSystemGrMaxBonus = (data: number[]): number => {
  let sum = 0;
  data &&
    data.map((it) => {
      sum += it;
    });
  return sum;
};
export const calculateSystemGrMinBonus = (data: number[]): number => {
  let minBonus = 0;
  if (data && data.length > 0) {
    const result = data.reduce((previous, current) =>
      previous < current ? current : previous
    );
    minBonus = result;
  }

  return minBonus;
};
export const calculateSystemMinBonus = (
  data: CheckedGroup[],
  limit?: string | number
): number => {
  let minBonus = 0;
  if (data && data.length > 0) {
    const result = data.reduce((previous, current) =>
      previous.minOdd < current.minOdd ? current : previous
    );
    minBonus = result.minBonus;
  }

  return Number(limit) && minBonus > Number(limit) ? Number(limit) : minBonus;
};
export const calculateSystemMaxBonus = (
  data: CheckedGroup[],
  limit?: string | number
): number => {
  let maxBonus = 0;
  if (data && data.length > 0) {
    const result = data.reduce((previous, current) =>
      previous.maxOdd < current.maxOdd ? previous : current
    );
    maxBonus = result.maxBonus;
  }
  return Number(limit) && maxBonus > Number(limit) ? Number(limit) : maxBonus;
};
export const calculateSystemTotBonus = (
  data: CheckedGroup[],
  limit?: string | number
): number => {
  let sum = 0;
  data &&
    data.map((it) => {
      sum += it.maxBonus;
    });
  return Number(limit) && sum > Number(limit) ? Number(limit) : sum;
};
export const calculateSystemMinWin = (
  data: CheckedGroup[],
  limit?: string | number
): number => {
  let minWin = 0;
  if (data && data.length > 0) {
    const result = data.reduce((previous, current) =>
      previous.minOdd < current.minOdd ? current : previous
    );
    minWin = result.minPotentialWin;
  }

  return Number(limit) && minWin > Number(limit) ? Number(limit) : minWin;
};
export const calculateSystemMaxWin = (data: CheckedGroup[]): number => {
  let maxWin = 0;
  if (data && data.length > 0) {
    const result = data.reduce((previous, current) =>
      previous.maxOdd < current.maxOdd ? previous : current
    );
    maxWin = result.maxPotentialWin;
  }

  return maxWin;
};
export const calculateSystemTotWin = (data: CheckedGroup[]): number => {
  let sum = 0;
  data.map((item) => {
    sum += item.maxPotentialWin;
  });
  return sum;
};
export const calculateBettingAmount = (
  data: Record<string, CheckedGroup>
): string => {
  let sum = 0;
  Object.values(data)?.map((item) => {
    sum += Number(item.bettingAmount);
  });
  return sum.toFixed(2).toString();
};
/***************************************
 *
 *
 *
 *
 * ********************************** */
export const composeCouponData = (
  data: PlayedCoupon
): Record<string, ICouponOdd[]> => {
  const obj: Record<string, ICouponOdd[]> = {};
  data &&
    data.odds &&
    data.odds
      .filter((odd) => odd.active)
      .map((odd) => {
        const newOdd = {
          id: odd.odd.id,
          eventId: odd.event.id,
          marketId: odd.market.id,
          oddLabel: odd.odd.label,
          spreadLabel: odd.odd.spread,
          marketLabel: odd.market.label,
          unique: odd.unique,
          value: odd.odd.value_updated,
          code: odd.code,
          caller: odd.caller,
          fix: odd.fix,
          eventLabel: odd.event.label,
          locked: odd.odd.locked,
          quick: "",
          tournamentId: odd.tournament.id,
        };
        if (obj[odd.event.id]) {
          obj[odd.event.id].push(newOdd);
        } else {
          obj[odd.event.id] = [newOdd];
        }
      });
  return obj;
};

export const composeCouponOdds = (data: PlayedCoupon): ICouponOdd[] => {
  let odds: ICouponOdd[] = [];
  data?.odds
    ?.filter((odd) => odd.active)
    .map((odd) => {
      const newOdd = {
        id: odd.odd.id,
        eventId: odd.event.id,
        marketId: odd.market.id,
        oddLabel: odd.odd.label,
        spreadLabel: odd.odd.spread,
        marketLabel: odd.market.label,
        unique: odd.unique,
        value: odd.odd.value_updated,
        code: odd.code,
        caller: odd.caller,
        fix: odd.fix,
        eventLabel: odd.event.label,
        locked: odd.odd.locked,
        quick: "",
        tournamentId: odd.tournament.id,
      };
      odds = [...odds, newOdd];
    });
  return odds;
};
