import { instanceAxios } from "services/axiosServices";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getPayMethodsRequested,
  getPayMethodsSucceded,
  getPayMethodsFailed,
  getPayMethodContentRequested,
  getPayMethodContentSucceded,
  getPayMethodContentFailed,
} from "./paymentsSlice";

export function* getPayMethods(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/payment/list");
    const { data } = response.data;
    yield put(getPayMethodsSucceded(data));
  } catch (error) {
    yield put(getPayMethodsFailed());
  }
}
export function* getPayMethodContent({
  payload,
}: ReturnType<typeof getPayMethodContentRequested>): unknown {
  try {
    const { id, payloadData } = payload;
    const response = yield call(
      instanceAxios.post,
      `/api/payment/exec/${id}`,
      payloadData
    );
    const { data } = response.data;
    yield put(getPayMethodContentSucceded(data));
  } catch (error) {
    yield put(getPayMethodContentFailed());
  }
}

export function* ongetPayMethodsRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getPayMethodsRequested, getPayMethods);
}
export function* ongetPayMethodContentRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getPayMethodContentRequested, getPayMethodContent);
}

export function* paymentsSagas(): unknown {
  yield all([
    call(ongetPayMethodsRequested),
    call(ongetPayMethodContentRequested),
  ]);
}
