import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WalletType } from "../wallet/walletSlice";

export interface ConfigState {
  isFetching: boolean;
  currency?: Currency[];
  walletType?: WalletType[];
  transactionType?: TransactionType[];
  casinoLive?: Record<string, CasinoLive>;
  virtual?: Record<string, Virtual>;
  poker?: Record<string, Poker>;
  streaming?: Record<string, Streaming>;
  casino?: Record<string, Casino>;
  skin?: Record<string, Skin>;
  banners: any;
  isLiveChatOpen: boolean;
  oddsType: OddType;
}

export interface OddType {
  type: string;
  label: string;
}

export interface Currency {
  id: number;
  label: string;
  enabled: number;
  decimal: number;
  symbol: string;
  divider: number;
}
export interface TransactionType {
  id: number;
  label: string;
  enabled: boolean;
  affect: string;
  operation: string;
}

export interface WalletConfigResponsePayload {
  currency?: Currency[];
  wallettype: WalletType[];
  transactiontype: TransactionType[];
}
export interface Skin {
  betcancel: number;
  registration: number;
  payment: number;
  deafult_agency: number;
  welcome_bonus: number;
  id_wb_user: number;
  id_wb_father: number;
  symbol: string;
  currency: string;
  livechat?: number;
  always_transfer?: number;
}
export interface SkinConfig {
  casinolive: Record<string, CasinoLive>;
  casino: Record<string, Casino>;
  poker: Record<string, Poker>;
  virtual: Record<string, Virtual>;
  streaming: Record<string, Streaming>;
  skin: Record<string, Skin>;
}
export interface Virtual {
  enabled: number;
}
export interface Streaming {
  enabled: number;
}
export interface CasinoLive {
  enabled: number;
}
export interface Poker {
  enabled: number;
}
export interface Casino {
  enabled: number;
}
export interface Error {
  message: string;
}
const initialState: ConfigState = {
  isFetching: false,
  currency: undefined,
  walletType: undefined,
  transactionType: undefined,
  casinoLive: undefined,
  virtual: undefined,
  poker: undefined,
  streaming: undefined,
  casino: undefined,
  skin: undefined,
  banners: undefined,
  isLiveChatOpen: false,
  oddsType: {
    type: "decimal",
    label: "Dec",
  },
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    getWalletConfigRequested: (state: ConfigState) => {
      state.isFetching = true;
    },
    getWalletConfigSucceded: (
      state: ConfigState,
      action: PayloadAction<WalletConfigResponsePayload>
    ) => {
      const { currency, transactiontype, wallettype } = action.payload;
      state.isFetching = false;
      state.currency = currency;
      state.transactionType = transactiontype;
      state.walletType = wallettype;
    },
    getWalletConfigFailed: (state: ConfigState) => {
      state.isFetching = false;
    },
    getBannersRequested: (state: ConfigState) => {
      state.isFetching = true;
    },
    getBannersSucceded: (state: ConfigState, action: PayloadAction<any>) => {
      state.isFetching = false;
      state.banners = action.payload;
    },
    getBannersFailed: (state: ConfigState) => {
      state.isFetching = false;
    },
    getSkinConfigRequested: (state: ConfigState) => {
      state.isFetching = true;
    },
    getSkinConfigSucceded: (
      state: ConfigState,
      action: PayloadAction<SkinConfig>
    ) => {
      const { casinolive, virtual, poker, streaming, casino, skin } =
        action.payload;
      state.isFetching = false;
      state.casinoLive = casinolive;
      state.virtual = virtual;
      state.poker = poker;
      state.streaming = streaming;
      state.casino = casino;
      state.skin = skin;
    },
    getSkinConfigFailed: (state: ConfigState) => {
      state.isFetching = false;
    },
    getAuthSkinConfigRequested: (
      state: ConfigState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getAuthSkinConfigSucceded: (
      state: ConfigState,
      action: PayloadAction<SkinConfig>
    ) => {
      const { casinolive, virtual, poker, streaming, casino, skin } =
        action.payload;
      state.isFetching = false;
      state.casinoLive = casinolive;
      state.virtual = virtual;
      state.poker = poker;
      state.streaming = streaming;
      state.casino = casino;
      state.skin = skin;
    },
    getAuthSkinConfigFailed: (state: ConfigState) => {
      state.isFetching = false;
    },
    openLiveChat: (state: ConfigState, action: PayloadAction<boolean>) => {
      state.isLiveChatOpen = action.payload;
    },
    handleOddsTypeChange: (
      state: ConfigState,
      action: PayloadAction<OddType>
    ) => {
      state.oddsType = action.payload;
    },
    updateSkinSettings: (state: ConfigState, action: PayloadAction<number>) => {
      state.skin = state.skin && {
        ...state.skin,
        ["setting"]: {
          ...state.skin["setting"],
          ["always_transfer"]: action.payload,
        },
      };
    },
  },
});

export const {
  getWalletConfigRequested,
  getWalletConfigSucceded,
  getWalletConfigFailed,
  getBannersRequested,
  getBannersSucceded,
  getBannersFailed,
  getSkinConfigRequested,
  getSkinConfigSucceded,
  getSkinConfigFailed,
  getAuthSkinConfigRequested,
  getAuthSkinConfigSucceded,
  getAuthSkinConfigFailed,
  openLiveChat,
  handleOddsTypeChange,
  updateSkinSettings,
} = configSlice.actions;

export default configSlice.reducer;
