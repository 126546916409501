import { GamesListResponsePayload, Provider, ProviderGame } from "./gamesSlice";

export const flatCasinoGames = (
  response: GamesListResponsePayload
): ProviderGame[] | undefined => {
  const a = response?.providers?.map((provider: Provider) => {
    return provider?.games?.map((game: any) => {
      return game;
    });
  });
  return a?.flat();
};
export const flatCasinoLiveGames = (
  response: GamesListResponsePayload
): ProviderGame[] | undefined => {
  const a = response?.providers?.map((provider: Provider) => {
    return provider?.games?.map((game: any | undefined) => {
      return game;
    });
  });
  return a?.flat();
};
