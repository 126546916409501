import { instanceAxios } from "services/axiosServices";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getHighlightsRequested,
  getHighlightsFailed,
  getHighlightsSucceded,
  getCasinoRequested,
  getCasinoSucceded,
  getCasinoFailed,
  getGameRequested,
  getGameSucceded,
  getCasinoLiveRequested,
  getCasinoLiveSucceded,
  getCasinoLiveFailed,
  getVirtualRequested,
  getVirtualSucceded,
  getVirtualFailed,
} from "./gamesSlice";

export function* getHighlights(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/games/highlights");
    const { data } = response.data;
    yield put(getHighlightsSucceded(data));
  } catch (error) {
    yield put(getHighlightsFailed());
  }
}
export function* getCasino(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/games/list/casino");
    const { data } = response.data;
    yield put(getCasinoSucceded(data));
  } catch (error) {
    yield put(getCasinoFailed());
  }
}
export function* getCasinoLive(): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/games/list/casinolive`
    );
    const { data } = response.data;
    yield put(getCasinoLiveSucceded(data));
  } catch (error) {
    yield put(getCasinoLiveFailed());
  }
}
export function* getVirtual(): unknown {
  try {
    const response = yield call(instanceAxios.get, `/api/games/list/virtual`);
    const { data } = response.data;
    yield put(getVirtualSucceded(data));
  } catch (error) {
    yield put(getVirtualFailed());
  }
}
export function* getGame({
  payload,
}: ReturnType<typeof getGameRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/games/start/${payload}`
    );
    const { data } = response.data;
    yield put(getGameSucceded(data));
  } catch (error) {
    console.log(error);
  }
}

export function* ongetCasinoRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getCasinoRequested, getCasino);
}
export function* ongetHighlightsRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getHighlightsRequested, getHighlights);
}
export function* ongetCasinoLiveRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getCasinoLiveRequested, getCasinoLive);
}
export function* ongetVirtualRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getVirtualRequested, getVirtual);
}
export function* ongetGameRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getGameRequested, getGame);
}

export function* gamesSagas(): Generator<unknown, void, unknown> {
  yield all([
    call(ongetCasinoRequested),
    call(ongetGameRequested),
    call(ongetCasinoLiveRequested),
    call(ongetHighlightsRequested),
    call(ongetVirtualRequested),
  ]);
}
