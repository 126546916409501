import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DialogState {
  isDialogOpen: boolean;
  dialogContent: string | undefined;
  dialogData?: any;
  isTransactionsrDialogOpen: boolean;
}

export interface OpenDialogPayload {
  type: string;
  payload?: any;
}

const initialState: DialogState = {
  isDialogOpen: false,
  dialogContent: undefined,
  dialogData: undefined,
  isTransactionsrDialogOpen: false,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openDialog: (
      state: DialogState,
      action: PayloadAction<OpenDialogPayload>
    ) => {
      state.isDialogOpen = true;
      state.dialogContent = action.payload.type;
      state.dialogData = action.payload.payload;
    },
    closeDialog: (state: DialogState) => {
      state.isDialogOpen = false;
      state.dialogContent = undefined;
    },
    handleTransactionsDialog: (
      state: DialogState,
      action: PayloadAction<boolean>
    ) => {
      state.isTransactionsrDialogOpen = action.payload;
    },
  },
});

export const { openDialog, closeDialog, handleTransactionsDialog } =
  dialogSlice.actions;

export default dialogSlice.reducer;
