import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Currency } from "../config/configSlice";
import { WalletType } from "../wallet/walletSlice";

export interface AuthState {
  isAuth: boolean;
  isFetching: boolean;
  user?: User;
  authWallet?: Wallet;
  locale: string;
  permissions?: string[];
  isBalanceVisible: boolean;
  isAdminAuth: boolean;
}
export interface CurrentUser {
  username?: string;
  password: string;
  c_password?: string;
  o_password?: string;
  referral?: number;
}

export interface ResetRequest {
  username: string;
  email: string;
}

export interface User {
  id?: number;
  username: string;
  email: string;
  fiscal_code: string;
  firstname: string;
  lastname: string;
  phone: string;
  enabled?: boolean;
  wallet_id?: number;
  display_name?: string;

  password: string;
  c_password: string;
}
export interface Wallet {
  id: number;
  name: string;
  overdraft: number;
  balance: number;
  children_overdraft: number;
  children_balance: number;
  children_availability: number;
  children_count: number;
  enabled: boolean;
  currency_id: number;
  currency: Currency;
  parent_id: number;
  availability: number;
  wallet_type?: WalletType;
  wallet_type_id: number;
  address: string;
  city_id: number;
  fee: number;
  total: number;
  vat: string;
  cap: string;
  provisional_plan: ProvigionalPlan;
  breadcum: string;
}

export interface ProvigionalPlan {
  id: number;
  label: string;
  name: string;
}

export interface Balance {
  service: string;
  balance: number;
}
export interface LoginResponse {
  wallet: Wallet;
  user: User;
  permissions: string[];
}

export interface PasswordData {
  o_password: string;
  password: string;
  c_password: string;
}

const initialState: AuthState = {
  isAuth: false,
  isFetching: false,
  user: undefined,
  authWallet: undefined,
  locale:
    process.env.REACT_APP_DEFAULT_LANGUAGE ||
    navigator.language.substring(0, 2) ||
    "en",
  permissions: undefined,
  isBalanceVisible: true,
  isAdminAuth: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequested: (state: AuthState, action: PayloadAction<CurrentUser>) => {
      state.isFetching = true;
      action;
    },
    loginSucceded: (state: AuthState, action: PayloadAction<LoginResponse>) => {
      const { user, wallet, permissions } = action.payload;
      state.isFetching = false;
      state.isAuth = true;
      state.user = user;
      state.authWallet = wallet;
      state.permissions = permissions;
    },
    loginFailed: (state: AuthState) => {
      state.isFetching = false;
      state.isAuth = false;
    },
    registerRequested: (
      state: AuthState,
      action: PayloadAction<CurrentUser>
    ) => {
      state.isFetching = true;
      action;
    },
    registerSucceded: (state: AuthState, action: PayloadAction<any>) => {
      state.isFetching = false;
    },
    registerFailed: (state: AuthState) => {
      state.isFetching = false;
    },
    updateWallet: (state: AuthState, action: PayloadAction<Wallet>) => {
      state.authWallet = action.payload;
    },
    logoutRequested: (state: AuthState) => {
      state.isFetching = true;
    },
    logoutSucceded: (state: AuthState) => {
      state.isFetching = false;
      state.isAuth = false;
      state.user = undefined;
      state.authWallet = undefined;
      state.permissions = undefined;
      state.isBalanceVisible = true;

      // state.wallet && websocket.leave(`wallet.${state.wallet.id}`);
    },
    logoutFailed: (state: AuthState) => {
      state.isAuth = false;
    },
    setLang: (state: AuthState, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    toggleBalance: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isBalanceVisible = action.payload;
    },
    requestChangePassword: (
      state: AuthState,
      action: PayloadAction<CurrentUser>
    ) => {
      state.isFetching = true;
      action;
    },
    changePasswordFailed: (state: AuthState) => {
      state.isFetching = false;
    },
    requestResetPassword: (
      state: AuthState,
      action: PayloadAction<ResetRequest>
    ) => {
      state.isFetching = true;
      action;
    },
    resetPasswordSucceded: (state: AuthState) => {
      state.isFetching = false;
    },
    resetPasswordFailed: (state: AuthState) => {
      state.isFetching = false;
    },
    getAuthDetailsRequested: (state: AuthState) => {
      state.isFetching = true;
    },
    getAuthDetailsSucceded: (
      state: AuthState,
      action: PayloadAction<LoginResponse>
    ) => {
      const { user, wallet, permissions } = action.payload;
      state.isFetching = false;
      state.isAuth = true;
      state.user = user;
      state.authWallet = wallet;
      state.permissions = permissions;
    },
    getAuthDetailsFailed: (state: AuthState) => {
      state.isFetching = false;
    },
    setAdminAuth: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isAdminAuth = action.payload;
    },
    clearAuthState: (state: AuthState) => {
      state.isFetching = false;
      state.isAuth = false;
      state.user = undefined;
      state.authWallet = undefined;
      state.permissions = undefined;
    },
  },
});

export const {
  loginRequested,
  loginSucceded,
  loginFailed,
  registerRequested,
  registerSucceded,
  registerFailed,
  logoutRequested,
  logoutSucceded,
  logoutFailed,
  setLang,
  requestChangePassword,
  changePasswordFailed,
  updateWallet,
  clearAuthState,
  requestResetPassword,
  resetPasswordSucceded,
  resetPasswordFailed,
  toggleBalance,
  getAuthDetailsRequested,
  getAuthDetailsSucceded,
  getAuthDetailsFailed,
  setAdminAuth,
} = authSlice.actions;

export default authSlice.reducer;
