import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as jsonpatch from "fast-json-patch";
import {
  composeLiveData,
  orderCategories,
  orderTournaments,
  composeoverviewMarkets,
} from "./live.utils";
import { isObjectEmpty } from "services/utils";

export interface LiveState {
  isFetchingSnapshot: boolean;
  isFetchingEventSnapshot: boolean;
  isFetchingVideo: boolean;
  isFetchingCalendarData: boolean;
  palimpsest: Record<string, Record<string, Palimpsest>>;
  marketsConfig: Record<string, Record<string, MarketConfig>>;
  eventsConfig?: Record<string, LiveEvent>;
  mainMarkets?: Record<string, Record<string, LiveMarket>>;
  eventMarkets?: Record<string, LiveMarket>;
  composedSports?: Record<string, number[]>;
  composedCategories?: Record<string, number[]>;
  composedTournaments?: Record<string, Record<string, number>[]>;
  orderedSports?: Palimpsest[];
  orderedCategories?: Palimpsest[];
  orderedTournaments?: Palimpsest[];
  selectedFilter: string;
  selectedSport?: number;
  selectedCategory: string | number;
  selectedEvent?: number;
  defaultFirstEvent?: number;
  view: string;
  overviewMarkets?: MarketConfig[];
  overviewSelectedMarkets?: MarketConfig[];
  isVideoFilter: boolean;
  timed: boolean;
  videoUrl?: string;
  responsive: string;
  isDesktopLayoutLive: boolean;
  /////////calendar/////////
  calendarData: Record<string, Record<string, Palimpsest>>;
  composedCalendarSports?: Record<string, number[]>;
  composedCalendarCategories?: Record<string, number[]>;
  composedCalendarTournaments?: Record<string, Record<string, number>[]>;
  orderedCalendarSports?: Palimpsest[];
  orderedCalendarCategories?: Palimpsest[];
  orderedCalendarTournaments?: Palimpsest[];
  selectedCalendarSport?: number;
  selectedCalendarCategory: string | number;
}

export interface Palimpsest {
  type: string;
  label: string;
  icon: string;
  id: number;
  sport_id: number;
  category_id: number;
  timed: boolean;
  order: number;
  widget: boolean;
  categoryLabel?: string;
}

export interface MarketConfig {
  id: number;
  label: string;
  odds: Record<string, LiveOddLabel>;
  sport_id: number;
  group_id: number;
  main: boolean;
  order: number;
  spread: boolean;
  player: string;
}
export interface LiveMarket {
  id: number;
  odds: Record<string, Record<string, LiveOdd>>;
  extra?: string;
}

export interface LiveEvent {
  id: number;
  away: string;
  date: string;
  home: string;
  label: string;
  short: number;
  phase: string;
  result: string;
  score: string;
  time: string;
  timer: string;
  begin: number;
  sport_id: number;
  category_id: number;
  tournament_id: number;
  gamescore: string | null;
  started: boolean;
  tournamentLabel: string;
  stream_id: number;
  widget?: boolean;
}
export interface LiveOddLabel {
  id: number;
  label: string;
  order: number;
}
export interface LiveOdd {
  id: number;
  locked: boolean;
  code: string;
  unique: string;
  value?: number;
  extra?: string;
}

const initialState: LiveState = {
  isFetchingSnapshot: false,
  isFetchingEventSnapshot: false,
  isFetchingVideo: false,
  isFetchingCalendarData: false,
  palimpsest: {},
  marketsConfig: {},
  eventsConfig: undefined,
  mainMarkets: undefined,
  eventMarkets: undefined,
  composedSports: undefined,
  composedCategories: undefined,
  composedTournaments: undefined,
  orderedSports: undefined,
  orderedCategories: undefined,
  orderedTournaments: undefined,
  selectedFilter: "",
  selectedSport: undefined,
  selectedCategory: "all",
  selectedEvent: undefined,
  defaultFirstEvent: undefined,
  view: "overview",
  overviewMarkets: undefined,
  overviewSelectedMarkets: undefined,
  isVideoFilter: false,
  timed: false,
  videoUrl: undefined,
  responsive: "",
  isDesktopLayoutLive: false,
  ////////////////////////////
  calendarData: {},
  composedCalendarSports: undefined,
  composedCalendarCategories: undefined,
  composedCalendarTournaments: undefined,
  orderedCalendarSports: undefined,
  orderedCalendarCategories: undefined,
  orderedCalendarTournaments: undefined,
  selectedCalendarSport: undefined,
  selectedCalendarCategory: "all",
};

export const liveSlice = createSlice({
  name: "live",
  initialState,
  reducers: {
    getSnapshotRequested: (state: LiveState) => {
      state.isFetchingSnapshot = true;
      state.palimpsest = {};
      state.marketsConfig = {};
      state.eventsConfig = undefined;
      state.mainMarkets = undefined;
      state.view = "overview";
      state.orderedTournaments = undefined;
      state.selectedEvent = undefined;
      state.defaultFirstEvent = undefined;
      state.selectedSport = undefined;
      state.isVideoFilter = false;
    },
    getSnapshotSucceded: (state: LiveState, action: PayloadAction<string>) => {
      const json = JSON.parse(action.payload);
      state.isFetchingSnapshot = false;
      state.palimpsest =
        json["config_palimpsest"] !== null ? json["config_palimpsest"] : {};
      state.marketsConfig =
        json["config_markets"] !== null ? json["config_markets"] : {};
      state.eventsConfig =
        json["events_labels"] !== null ? json["events_labels"] : undefined;
      state.mainMarkets =
        json["events_markets"] !== null ? json["events_markets"] : undefined;

      if (json["events_markets"] && !isObjectEmpty(json["events_markets"])) {
        const liveData = composeLiveData(
          json["events_labels"],
          json["config_palimpsest"]["sports"],
          state.isVideoFilter
        );
        state.composedSports = liveData["composedData"]["sportsObj"];
        state.composedCategories = liveData["composedData"]["categoriesObj"];
        state.composedTournaments = liveData["composedData"]["tournamentsObj"];
        state.orderedSports = liveData["orderedData"]["orderedSports"];
        state.composedSports &&
          (state.orderedCategories = orderCategories(
            state.palimpsest.categories,
            state.composedSports,
            state.selectedSport
          ));
        state.orderedCategories &&
          state.composedCategories &&
          (state.orderedTournaments = orderTournaments(
            state.palimpsest.categories,
            state.orderedCategories,
            state.palimpsest.tournaments,
            state.composedCategories,
            state.selectedCategory
          ));
      }
    },
    getSnapshotFailed: (state: LiveState) => {
      state.isFetchingSnapshot = false;
    },
    getEventSnapshotRequested: (
      state: LiveState,
      action: PayloadAction<number | undefined>
    ) => {
      state.isFetchingEventSnapshot = true;
      action;
    },
    getEventSnapshotSucceded: (
      state: LiveState,
      action: PayloadAction<string>
    ) => {
      const json: Record<string, LiveMarket> = JSON.parse(action.payload);
      state.isFetchingEventSnapshot = false;
      state.eventMarkets = json;
    },
    getEventSnapshotFailed: (state: LiveState) => {
      state.isFetchingEventSnapshot = false;
    },
    updatePalimpsestConfig: (state: LiveState, action: PayloadAction<any>) => {
      action.payload.update == null
        ? (state.palimpsest = {})
        : (state.palimpsest = jsonpatch.applyPatch(
            state.palimpsest,
            jsonpatch.deepClone(action.payload.update),
            false,
            false
          ).newDocument);
      /**only if an item is removed or added in palimpsest updates,
       * recompose live data */
      if (
        action.payload.update.find(
          (el: any) => el.op.includes("remove") || el.op.includes("add")
        )
      ) {
        if (state.eventsConfig && !isObjectEmpty(state.eventsConfig)) {
          const liveData = composeLiveData(
            state.eventsConfig,
            state.palimpsest.sports,
            state.isVideoFilter
          );
          state.composedSports = liveData["composedData"]["sportsObj"];
          state.composedCategories = liveData["composedData"]["categoriesObj"];
          state.composedTournaments =
            liveData["composedData"]["tournamentsObj"];
          state.orderedSports = liveData["orderedData"]["orderedSports"];
          /** order catoegories if palimpsest  changes */
          state.composedSports &&
            (state.orderedCategories = orderCategories(
              state.palimpsest.categories,
              state.composedSports,
              state.selectedSport
            ));

          /** order tournaments if palimpsest  changes */
          state.orderedCategories &&
            state.composedCategories &&
            (state.orderedTournaments = orderTournaments(
              state.palimpsest.categories,
              state.orderedCategories,
              state.palimpsest.tournaments,
              state.composedCategories,
              state.selectedCategory
            ));
        }
      }
    },
    updateMarketsConfig: (state: LiveState, action: PayloadAction<any>) => {
      action.payload.update == null
        ? (state.marketsConfig = {})
        : (state.marketsConfig = jsonpatch.applyPatch(
            state.marketsConfig,
            jsonpatch.deepClone(action.payload.update),
            false,
            false
          ).newDocument);
      if (
        action.payload.update.find(
          (el: any) => el.op.includes("remove") || el.op.includes("add")
        )
      ) {
        const composedMarkets =
          state.selectedSport &&
          state.marketsConfig.markets &&
          composeoverviewMarkets(
            state.marketsConfig.markets,
            state.selectedSport
          );
        if (composedMarkets) {
          state.overviewMarkets = composedMarkets;
          state.responsive !== "mobile"
            ? (state.overviewSelectedMarkets = composedMarkets.slice(0, 4))
            : (state.overviewSelectedMarkets = composedMarkets.slice(0, 1));
        }
      }
    },
    updateEventsConfig: (state: LiveState, action: PayloadAction<any>) => {
      action.payload.update == null
        ? (state.eventsConfig = undefined)
        : (state.eventsConfig = jsonpatch.applyPatch(
            state.eventsConfig ? state.eventsConfig : {},
            jsonpatch.deepClone(action.payload.update),
            false,
            false
          ).newDocument);
      /**only in add or remove of events you recompose sports,categories, and tournaments */
      if (
        action.payload.update.find(
          (el: any) => el.op.includes("remove") || el.op.includes("add")
        )
      ) {
        if (state.eventsConfig && !isObjectEmpty(state.eventsConfig)) {
          const topbarData = composeLiveData(
            state.eventsConfig,
            state.palimpsest.sports,
            state.isVideoFilter
          );
          state.composedSports = topbarData["composedData"]["sportsObj"];
          state.composedCategories =
            topbarData["composedData"]["categoriesObj"];
          state.orderedSports = topbarData["orderedData"]["orderedSports"];
          state.composedSports &&
            (state.orderedCategories = orderCategories(
              state.palimpsest.categories,
              state.composedSports,
              state.selectedSport
            ));
          state.composedTournaments =
            topbarData["composedData"]["tournamentsObj"];
          state.composedCategories &&
            state.orderedCategories &&
            (state.orderedTournaments = orderTournaments(
              state.palimpsest.categories,
              state.orderedCategories,
              state.palimpsest.tournaments,
              state.composedCategories,
              state.selectedCategory
            ));
        } else {
          (state.composedSports = undefined),
            (state.composedCategories = undefined),
            (state.composedTournaments = undefined),
            (state.orderedSports = undefined),
            (state.orderedCategories = undefined),
            (state.orderedTournaments = undefined),
            (state.view = "overview"),
            (state.overviewMarkets = undefined),
            (state.overviewSelectedMarkets = undefined),
            (state.selectedSport = undefined),
            (state.selectedCategory = "all"),
            (state.selectedEvent = undefined);
        }
      }
    },
    updateMarkets: (state: LiveState, action: PayloadAction<any>) => {
      action.payload.update == null
        ? (state.mainMarkets = undefined)
        : (state.mainMarkets = jsonpatch.applyPatch(
            state.mainMarkets ? state.mainMarkets : {},
            jsonpatch.deepClone(action.payload.update),
            false,
            false
          ).newDocument);
    },
    updateEventMarkets: (state: LiveState, action: PayloadAction<any>) => {
      action.payload.update == null
        ? (state.eventMarkets = undefined)
        : (state.eventMarkets = jsonpatch.applyPatch(
            state.eventMarkets ? state.eventMarkets : {},
            jsonpatch.deepClone(action.payload.update),
            false,
            false
          ).newDocument);
    },
    getVideoRequested: (state: LiveState, action: PayloadAction<number>) => {
      state.isFetchingVideo = true;
      action;
    },
    getVideoSucceded: (state: LiveState, action: PayloadAction<string>) => {
      state.isFetchingVideo = false;
      state.videoUrl = action.payload;
    },
    getVideoFailed: (state: LiveState) => {
      state.isFetchingVideo = false;
    },
    saveSelectedSport: (
      state: LiveState,
      action: PayloadAction<number | undefined>
    ) => {
      state.selectedSport = action.payload;
      state.composedSports &&
        (state.orderedCategories = orderCategories(
          state.palimpsest.categories,
          state.composedSports,
          action.payload
        ));
      state.orderedCategories &&
        state.composedCategories &&
        (state.orderedTournaments = orderTournaments(
          state.palimpsest.categories,
          state.orderedCategories,
          state.palimpsest.tournaments,
          state.composedCategories,
          state.selectedCategory
        ));

      const composedMarkets =
        state.marketsConfig.markets &&
        composeoverviewMarkets(state.marketsConfig.markets, action.payload);
      if (composedMarkets) {
        state.overviewMarkets = composedMarkets;
        state.responsive !== "mobile"
          ? (state.overviewSelectedMarkets = composedMarkets.slice(0, 4))
          : (state.overviewSelectedMarkets = composedMarkets.slice(0, 1));
      }
    },
    saveSelectedCategory: (
      state: LiveState,
      action: PayloadAction<string | number>
    ) => {
      state.selectedCategory = action.payload;
      state.orderedCategories &&
        state.composedCategories &&
        (state.orderedTournaments = orderTournaments(
          state.palimpsest.categories,
          state.orderedCategories,
          state.palimpsest.tournaments,
          state.composedCategories,
          action.payload
        ));
    },
    saveSelectedEvent: (
      state: LiveState,
      action: PayloadAction<number | undefined>
    ) => {
      state.selectedEvent = action.payload;
    },
    saveDefaultFirstEvent: (
      state: LiveState,
      action: PayloadAction<number | undefined>
    ) => {
      state.defaultFirstEvent = action.payload;
    },
    setView: (state: LiveState, action: PayloadAction<string>) => {
      state.view = action.payload;
    },
    setSelectedFilter: (state: LiveState, action: PayloadAction<string>) => {
      state.selectedFilter = action.payload;
    },
    setFilterVideo: (state: LiveState, action: PayloadAction<boolean>) => {
      state.isVideoFilter = action.payload;
      if (state.eventsConfig && !isObjectEmpty(state.eventsConfig)) {
        const topbarData = composeLiveData(
          state.eventsConfig,
          state.palimpsest.sports,
          state.isVideoFilter
        );
        state.composedSports = topbarData["composedData"]["sportsObj"];
        state.composedCategories = topbarData["composedData"]["categoriesObj"];
        state.orderedSports = topbarData["orderedData"]["orderedSports"];
        state.composedSports &&
          (state.orderedCategories = orderCategories(
            state.palimpsest.categories,
            state.composedSports,
            state.selectedSport
          ));
        state.composedTournaments =
          topbarData["composedData"]["tournamentsObj"];
        state.composedCategories &&
          state.orderedCategories &&
          (state.orderedTournaments = orderTournaments(
            state.palimpsest.categories,
            state.orderedCategories,
            state.palimpsest.tournaments,
            state.composedCategories,
            state.selectedCategory
          ));
      }
    },
    updateOverviewSelectedMarkets: (
      state: LiveState,
      action: PayloadAction<MarketConfig[]>
    ) => {
      state.overviewSelectedMarkets = action.payload;
    },
    clearState: (state: LiveState) => {
      state.view = "overview";
      state.selectedFilter = "";
      state.selectedCategory = "all";
      state.isVideoFilter = false;
      state.timed = false;
      state.selectedEvent = undefined;
      state.defaultFirstEvent = undefined;
      state.selectedSport = undefined;
    },
    toggleAppState: (state: LiveState, action: PayloadAction<string>) => {
      state.responsive = action.payload;
      state.overviewMarkets &&
        (action.payload !== "mobile"
          ? (state.overviewSelectedMarkets = state.overviewMarkets.slice(0, 4))
          : (state.overviewSelectedMarkets = state.overviewMarkets.slice(
              0,
              1
            )));
    },
    toggleDesktopLayoutLive: (state: LiveState) => {
      state.isDesktopLayoutLive = !state.isDesktopLayoutLive;
    },
    getCalendarDataRequested: (state: LiveState) => {
      state.isFetchingCalendarData = true;
    },
    getCalendarDataSucceded: (state: LiveState, action: PayloadAction<any>) => {
      state.isFetchingCalendarData = false;
      state.calendarData = action.payload;
      const { categories, sports, tournaments, events } = action.payload;
      const liveData = composeLiveData(events, sports, false);
      state.composedCalendarSports = liveData["composedData"]["sportsObj"];
      state.composedCalendarCategories =
        liveData["composedData"]["categoriesObj"];
      state.composedCalendarTournaments =
        liveData["composedData"]["tournamentsObj"];
      state.orderedCalendarSports = liveData["orderedData"]["orderedSports"];
      state.composedCalendarSports &&
        (state.orderedCategories = orderCategories(
          categories,
          state.composedCalendarSports,
          state.selectedCalendarSport
        ));
      state.orderedCalendarCategories &&
        state.composedCalendarCategories &&
        (state.orderedCalendarTournaments = orderTournaments(
          categories,
          state.orderedCalendarCategories,
          tournaments,
          state.composedCalendarCategories,
          state.selectedCalendarCategory
        ));
    },
    getCalendarDataFailed: (state: LiveState) => {
      state.isFetchingCalendarData = false;
    },
    saveSelectedCalendarSport: (
      state: LiveState,
      action: PayloadAction<number | undefined>
    ) => {
      state.selectedCalendarSport = action.payload;
      state.composedCalendarSports &&
        (state.orderedCalendarCategories = orderCategories(
          state.calendarData.categories,
          state.composedCalendarSports,
          action.payload
        ));
      state.orderedCalendarCategories &&
        state.composedCalendarCategories &&
        (state.orderedCalendarTournaments = orderTournaments(
          state.calendarData.categories,
          state.orderedCalendarCategories,
          state.calendarData.tournaments,
          state.composedCalendarCategories,
          state.selectedCalendarCategory
        ));
    },
    saveSelectedCalendarCategory: (
      state: LiveState,
      action: PayloadAction<string | number>
    ) => {
      state.selectedCalendarCategory = action.payload;
      state.orderedCalendarCategories &&
        state.composedCalendarCategories &&
        (state.orderedCalendarTournaments = orderTournaments(
          state.calendarData.categories,
          state.orderedCalendarCategories,
          state.calendarData.tournaments,
          state.composedCalendarCategories,
          action.payload
        ));
    },
    clearClendarState: (state: LiveState) => {
      (state.calendarData = {}),
        (state.composedCalendarSports = undefined),
        (state.composedCalendarCategories = undefined),
        (state.composedCalendarTournaments = undefined),
        (state.orderedCalendarSports = undefined),
        (state.orderedCalendarCategories = undefined),
        (state.orderedCalendarTournaments = undefined),
        (state.selectedCalendarSport = undefined),
        (state.selectedCalendarCategory = "all");
    },
  },
});

export const {
  setView,
  setSelectedFilter,
  setFilterVideo,
  saveSelectedEvent,
  saveDefaultFirstEvent,
  saveSelectedSport,
  saveSelectedCategory,
  updatePalimpsestConfig,
  updateMarketsConfig,
  updateEventsConfig,
  updateMarkets,
  clearState,
  getVideoRequested,
  getVideoSucceded,
  getVideoFailed,
  toggleAppState,
  getSnapshotRequested,
  getSnapshotSucceded,
  getSnapshotFailed,
  getEventSnapshotRequested,
  getEventSnapshotSucceded,
  getEventSnapshotFailed,
  updateOverviewSelectedMarkets,
  updateEventMarkets,
  toggleDesktopLayoutLive,
  getCalendarDataRequested,
  getCalendarDataSucceded,
  getCalendarDataFailed,
  saveSelectedCalendarSport,
  saveSelectedCalendarCategory,
  clearClendarState,
} = liveSlice.actions;

export default liveSlice.reducer;
