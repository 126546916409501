import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { store, persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { loadLanguage } from "services/i18n";

import "./index.scss";
const skin = process.env.REACT_APP_SKIN_NAME as string;
const environment = process.env.REACT_APP_ENVIRONMENT as string;

if (!environment.toLowerCase().match(/^development$|^test$|^production$/)) {
  throw new Error(
    "Invalid environment specified (development,test,production): use --environment=<ENVIRONMENT> as script option"
  );
}
if (skin) {
  const App = lazy(() => import(`skins/${skin}/App`));
  loadLanguage(skin);
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={null}>
        <Provider store={store}>
          <Router>
            <PersistGate persistor={persistor}>
              <App />
            </PersistGate>
          </Router>
        </Provider>
      </Suspense>
    </React.StrictMode>,
    document.querySelector("#root")
  );
} else {
  throw new Error("No skin specified: use --skin=<SKIN_NAME> as script option");
}
