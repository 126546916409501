import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NavigationState {
  visible: boolean;
  expandedNodes: string[];
  selectedNodes: string[];
  activeRoute?: number;
  isFooterOpen?: boolean;
}

const initialState: NavigationState = {
  visible: false,
  selectedNodes: [""],
  expandedNodes: [""],
  activeRoute: 10,
  isFooterOpen: false,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    showNavBar: (state: NavigationState) => {
      state.visible = true;
    },
    hideNavBar: (state: NavigationState) => {
      state.visible = false;
    },
    toggleNavBar: (state: NavigationState) => {
      state.visible = !state.visible;
    },
    navBarVisible: (state: NavigationState, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    expandedNavBarNodes: (
      state: NavigationState,
      action: PayloadAction<string[]>
    ) => {
      state.expandedNodes = action.payload;
    },
    selectedNavBarNodes: (
      state: NavigationState,
      action: PayloadAction<string[]>
    ) => {
      state.selectedNodes = action.payload;
    },
    clearNavBarState: (state: NavigationState) => {
      state.selectedNodes = ["2"];
      state.expandedNodes = ["1"];
    },
    setActiveRoute: (state: NavigationState, action: PayloadAction<number>) => {
      state.activeRoute = action.payload;
    },
    clearActiveRoute: (state: NavigationState) => {
      state.activeRoute = undefined;
    },
    toggleFooter: (state: NavigationState, action: PayloadAction<boolean>) => {
      state.isFooterOpen = action.payload;
    },
  },
});

export const {
  showNavBar,
  hideNavBar,
  toggleNavBar,
  navBarVisible,
  expandedNavBarNodes,
  selectedNavBarNodes,
  clearNavBarState,
  setActiveRoute,
  clearActiveRoute,
  toggleFooter,
} = navigationSlice.actions;

export default navigationSlice.reducer;
