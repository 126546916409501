import { all, call } from "redux-saga/effects";

import { authSagas } from "./auth/authSagas";
import { configSagas } from "./config/configSagas";
import { gamesSagas } from "./games/gamesSagas";
import { sportSagas } from "./sport/sportSagas";
import { liveSagas } from "./live/liveSagas";
import { walletSagas } from "./wallet/walletSagas";
import { couponSagas } from "./coupon/couponSagas";
import { paymentsSagas } from "./payments/paymentsSagas";

export default function* rootSaga(): Generator<unknown, void, unknown> {
  yield all([
    call(authSagas),
    call(gamesSagas),
    call(walletSagas),
    call(configSagas),
    call(sportSagas),
    call(liveSagas),
    call(couponSagas),
    call(paymentsSagas),
  ]);
}
