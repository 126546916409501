import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";
import authReducer from "./auth/authSlice";
import configReducer from "./config/configSlice";
import navigationReducer from "./navigation/navigationSlice";
import alertReducer from "./alert/alertSlice";
import gamesReducer from "./games/gamesSlice";
import walletReducer from "./wallet/walletSlice";
import sportReducer from "./sport/sportSlice";
import liveReducer from "./live/liveSlice";
import couponReducer from "./coupon/couponSlice";
import dialogReducer from "./dialog/dialogSlice";
import paymentsReducer from "./payments/paymentsSlice";
import layoutToggleReducer from "./layoutToggle/layoutToggleSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  i18n: i18nReducer,
  config: configReducer,
  navigation: navigationReducer,
  alert: alertReducer,
  dialog: dialogReducer,
  wallet: walletReducer,
  sport: sportReducer,
  live: liveReducer,
  games: gamesReducer,
  coupon: couponReducer,
  payments: paymentsReducer,
  layoutToggle: layoutToggleReducer,
});

export default rootReducer;
